import React, { useEffect, useState } from 'react';
import '../../styles/editcommand.css';
import { ApiInterfaces } from '../../../api/ApiInterfaces';
import { ApiRequests } from '../../../api/ApiRequests';
import { toast, ToastContainer } from 'react-toastify';

interface ModalProps {
  closeModal: () => void;
  info: (type: "warning" | "error" | "success", message: string) => void;
  nowCommand: string;
  nowResponse: string;
  commandId: number;
}

const EditCommandModal: React.FC<ModalProps> = ({ closeModal, info, nowCommand, nowResponse, commandId }) => {
  const [command, setCommand] = useState(nowCommand);
  const [response, setResponse] = useState(nowResponse);
  const [commands, setCommands] = useState<ApiInterfaces.WhapperCommands[]>([]);

  useEffect(() => {
    // Funções de busca na API
    const UsersData = async () => {
      const allMyCommands = await ApiRequests.ListMyCommands(); // Busca todos os comandos de todos os whappers disponiveis para esse usuário

      setCommands(allMyCommands);
    }

    UsersData()

    // Consulta à API a cada 5 segundos (ajuste o intervalo conforme necessário)
    const intervalId = setInterval(UsersData, 5000);

    // Certifique-se de limpar o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []);

  const handleCommandChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommand(e.target.value);
  };

  const handleResponseChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setResponse(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (command && response) {
        const requestAddCommand = await ApiRequests.UpdateCommand(commandId, command, response);

        if (requestAddCommand) {
          info("success", "Sucesso! O comando foi editado!");

          const allMyCommands = await ApiRequests.ListMyCommands(); // Busca todos os comandos de todos os whappers disponiveis para esse usuário

          setCommands(allMyCommands);
          closeModal();
        } else return toast.warning("Não foi possível inserir o comando para essa instância.");

      } else return toast.warning("Certifique-se que haja uma instância selecionada.");
    } catch (err) {
      toast.error("(500) Erro interno no servidor!");
    }
  }



  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Editando Comando</h2>
        <input
          type="text"
          placeholder="Digite o comando"
          value={command}
          onChange={handleCommandChange}
          className="modal-input"
        />
        <textarea
          placeholder="Resposta do comando"
          value={response}
          onChange={handleResponseChange}
          className="modal-textarea"
        />
        <button onClick={handleSubmit} className="modal-submit-button">
          Salvar Comando
        </button>
        <button onClick={closeModal} className="modal-close-button">Fechar</button>
      </div>
      <ToastContainer
        stacked
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default EditCommandModal;
