import { Link } from 'react-router-dom';
import '../styles/expired.css';
import referLinks from '../../utils/refer.links';

const ExpiredCredentials = () => {
  return (
    <section className="page_expired">
      <div className="container">
        <div className="expired_bg" />
        <div className="content_box_expired">
          <h3>Parece que suas credenciais expiraram</h3>
          <p>Por favor, faça login novamente para continuar.</p>
          <a href={referLinks.whapper_home + "/login"} className="link_home">Ir para o Login</a>
        </div>
      </div>
    </section>
  );
};

export default ExpiredCredentials;
