import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ApiHelpFuntions } from "../../api/ApiHelpFuntions";
import { ApiInterfaces } from "../../api/ApiInterfaces";
import { ApiRequests } from "../../api/ApiRequests";
import image from "../images/image.jpeg";
import profile from "../images/profile.jpg";
import "../styles/dashboard.css";
import "../styles/home.css";
import "../styles/users.css";


function UserInto() {
  const [allUsers, setAllUsers] = useState<ApiInterfaces.ListUsers[]>([]);
  const [selectedUser, setSelectedUser] = useState<ApiInterfaces.ListUsers>();
  const [allWhappers, setAllWhappers] = useState<ApiInterfaces.ListWhappers[]>();
  const [modalEditUserOpen, setModalEditUserOpen] = useState(false);
  const [modalEditpPassOpen, setModalEditPassOpen] = useState(false);
  const [userName, setUserName] = useState<string>("");
  const [passWord, setPassWord] = useState<string>("");

  useEffect(() => {
    // Funções de busca na API
    const UsersData = async () => {
      const allUsers = await ApiRequests.ListUsers(); // Buscando os usuários na plataforma.
      const allWhappers = await ApiRequests.ListWhappers(); // Busca os whappers na plataforma.
      const allMessages = await ApiRequests.ListMessages(); // Busca por todas as mensagens enviadas na plataforma.

      if (allUsers && allWhappers && allMessages) {
        setAllWhappers(allWhappers);
        setAllUsers(allUsers);
      }
    }

    UsersData()

    // Consulta à API a cada 5 segundos (ajuste o intervalo conforme necessário)
    const intervalId = setInterval(UsersData, 5000);

    // Certifique-se de limpar o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []);

  const updateUser = async (id: number) => {
    try {
      const allUsers = await ApiRequests.ListUsers();

      if (allUsers) setSelectedUser(allUsers.find(u => u.id == id));
    } catch(err) {
      console.log(err);
    }
  }

  const closeModalEditUser = () => { setModalEditUserOpen(false); setUserName(""); }
  const closeModalEditPass = () => { setModalEditPassOpen(false); setPassWord(""); }

  const confirmNewUsername = async () => {
    try {
      if (!selectedUser) return toast.warning("É preciso ter um usuário selecionado para essa ação.");

      if (userName.length == 0) return toast.warning("Nomes não podem ser vazios!");

      if (allUsers.find(u => u.username.toLowerCase() == userName.toLowerCase())) return toast.warning("Já existe um usuário com esse nome!");

      const editUsername = await ApiRequests.editUser(selectedUser.id, userName);

      if (editUsername) {
        toast.success("Nome de usuário alterado com sucesso!");
      } else {
        toast.warning("Não foi possível alterar o nome do usuário.");
      }

      await updateUser(selectedUser.id);
      setModalEditUserOpen(false);
      setUserName("");
    } catch (err) {
      console.log(err);
      toast.error("(500) Erro interno no servidor.");
      setModalEditUserOpen(false);
      setUserName("");
    }
  }

  const confirmNewPassWord = async () => {
    try {
      if (!selectedUser) return toast.warning("É preciso ter um usuário selecionado para essa ação.");

      if (passWord.length < 8) return toast.warning("A senha deve conter no mínimo 8 caracteres!");

      const editPassWorld = await ApiRequests.editUser(selectedUser.id, undefined, passWord);

      if (editPassWorld) {
        toast.success("Senha de usuário alterada com sucesso!");
      } else {
        toast.warning("Não foi possível alterar a senha do usuário.");
      }

      await updateUser(selectedUser.id);
      setModalEditPassOpen(false);
      setPassWord("");
    } catch (err) {
      console.log(err);
      toast.error("(500) Erro interno no servidor.");
      setModalEditPassOpen(false);
      setPassWord("");
    }
  }

  const handleUpdateActiveInactive = async (type: ("warming" | "protocolo" | "ai" | "trigger"), status: boolean): Promise<any> => {
    try {
      if (!selectedUser) return toast.warning("Selecione um usuário antes para editar!");

      const update = await ApiRequests.UpdateStatusService(selectedUser.id, type, status);

      if (update) {
        const allUsers = await ApiRequests.ListUsers();

        if (allUsers) setSelectedUser(allUsers.find(u => u.id == selectedUser.id));
        return toast.success("Sucesso ao atualizar o serviço!")
      } else {
        return toast.warning("Não foi possível atualizar o serviço, tente novamente mais tarde!");
      }
    } catch(err) {
      console.log(err);
      return toast.error("(500) Erro interno no servidor.");
    }
  }

  const handleChangeCountConnections = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      console.log(event.target.value);
      const connectionsCount = event.target.value;

      if (!selectedUser) return toast.warning("É preciso ter um usuário selecionado para essa ação.");

      const editNumberConnections = await ApiRequests.editUser(selectedUser.id, undefined, undefined, parseInt(connectionsCount));

      if (editNumberConnections) {
        toast.success("O número de instâncias do usuário foi alterada.");
      } else {
        toast.warning("Não foi possível alterar o número de instâncias do usuário.");
      }

      await updateUser(selectedUser.id);
    } catch(err) {
      toast.error("(500) Erro interno no servidor.");
      console.log(err);
    }
  }

  const handleChangeActiveAccount = async () => {
    try {
      if (!selectedUser) return toast.warning("É preciso ter um usuário selecionado para essa ação.");

      const editActiveAccount = await ApiRequests.editUser(selectedUser.id, undefined, undefined, undefined, !selectedUser.isLocked);

      if (editActiveAccount) {
        toast.success(`A conta do usuário foi ${selectedUser.isLocked ? "ATIVADA" : "DESATIVADA"} com sucesso!`);
      } else {
        toast.warning("Não foi possível atualizar o status da conta.");
      }

      await updateUser(selectedUser.id);
    } catch (err) {
      toast.error("(500) Erro interno no servidor.");
      console.log(err);
    }
  }

  return (
    <div className="users-data">
      <div className="left-user-page">
        <div className="users">
          <div className="header" >
            <i className='bx bxs-user-detail'></i>
            <h3>Gerenciar Usuários</h3>
            <h4 className="create-instance"><i className='bx bx-plus-circle' style={{marginRight:"5px", fontSize: "23px"}}></i> Criar</h4>
            {/* <i className='bx bx-filter' onClick={() => { setActionFilter(!actionFilter) }}></i> */}
          </div>
          <table>
            <thead>
              <tr>
                <th>Usuário</th>
                <th>Criação</th>
                <th>Perfil</th>
              </tr>
            </thead>
            <tbody>
              {
                allUsers.filter(user => user.pLevel !== 4).map((element, index) => (<tr onClick={() => setSelectedUser(!allUsers.filter(user => user.pLevel !== 4)?.[index] ? undefined : allUsers.filter(user => user.pLevel !== 4)[index] == selectedUser ? undefined : allUsers.filter(user => user.pLevel !== 4)[index])} key={index}>
                  <td>
                    <img src={image}></img>
                    <p>{element.username}</p>
                  </td>
                  <td>{ApiHelpFuntions.formatDate(element.createdAt)}</td>
                  <td><span {...{ className: `status ${element.pLevel == 3 ? "mod" : element.pLevel == 2 ? "client" : "tester"}` }}>{element.pLevel == 3 ? "Moderador" : element.pLevel == 2 ? "Cliente" : "Usuário"}</span></td>
                </tr>))
              }
            </tbody>
          </table>
        </div>
        <div className={`whappers ${selectedUser ? 'padding' : ''}`}>
          {
            !selectedUser
              ?
              (<h2 className="middleInfo">Selecione um Usuário...</h2>)
              :
              (
                <>
                  <div className="header" >
                    <i className='bx bx-send'></i>
                    <h3>Whappers de {selectedUser.username.charAt(0).toUpperCase() + selectedUser.username.slice(1)}</h3>
                  </div>
                  <div className="whappers-list">
                    <ul>
                      {
                        allWhappers && allWhappers.filter(e => e.owner_email.toLowerCase() == selectedUser.email.toLowerCase()).map(bot =>
                        (
                          <li title={bot.whapper_key}>
                            <img src={bot.whapper_picture ? bot.whapper_picture : profile}></img>
                            <a className="whapper-name-bot">{bot.whapper_name.length > 14 ? bot.whapper_name.slice(0, 14) + "..." : bot.whapper_name}</a> <a style={bot.terminated ? { backgroundColor: "yellow" } : bot.whapper_number == "Not Connected" ? { backgroundColor: "red" } : { backgroundColor: "green" }} className="info-whapper-connection">{bot.terminated ? "Terminado" : bot.whapper_number == "Not Connected" ? "Desconectado" : "Conectado"}</a>
                            <p className="buttons-whapper-info">
                              <i id="grey" title="Iniciar/Parar Instância" className='bx bx-skip-next' ></i>
                              <i id="blue" title="Testar Envio" className='bx bx-test-tube' ></i>
                              <i id="yellow" title="Editar Informações" className='bx bx-credit-card-front' ></i>
                              <i id="red" title="Terminar Instância" className='bx bx-x' ></i>
                            </p>
                          </li>
                        )
                        )
                      }
                    </ul>
                  </div>
                </>
              )
          }
        </div>
      </div>
      <div className="right-user-page">
        <div className={`user-info-data ${selectedUser ? 'padding' : ''}`}>
          {
            !selectedUser
              ?
              (<h2 className="middleInfo">Selecione um Usuário...</h2>)
              :
              (
                <>
                  <div className="header" >
                    <i className='bx bxs-pin'></i>
                    <h3>Perfil de {selectedUser.username.charAt(0).toUpperCase() + selectedUser.username.slice(1)}</h3>
                  </div>

                  <div className="profile-picture">
                    <p className="profile-picture-info"><i className='bx bx-image-alt' ></i>Foto de Perfil</p>
                    <div className="pictures">
                      <img className="picture" src={image} />
                      <button id="upload" className="picture-button">Fazer Upload</button>
                      <button id="remove" className="picture-button">Remover Imagem</button>
                    </div>
                  </div>

                  <div className="acess-info">
                    <p className="profile-acess-info"><i className='bx bxs-key' ></i>Informações de Login/Permissões</p>
                    <div className="container-profile-acess">
                      <div className="profile-acess">
                        <h4>Username: <a>{selectedUser.username}</a><i onClick={() => setModalEditUserOpen(true)} className='bx bx-edit-alt'></i></h4>
                        <h4 className="profile-acess-password">Password: <a>***********</a><i onClick={() => setModalEditPassOpen(true)} title="Resetar Senha" className='bx bx-trash' ></i></h4>
                      </div>
                      <div className="profile-permissions">
                        <h4>Status da Conta: <p onClick={handleChangeActiveAccount} className="profile-status" style={selectedUser.isLocked ? { backgroundColor: "#f36d55" } : { backgroundColor: "#55f355" }}>{selectedUser.isLocked ? "Inativa" : "Ativa"}</p></h4>
                        <h4>N° de Conexões:
                          <select onChange={handleChangeCountConnections} className="profile-number-of-connections" value={selectedUser.cConnections} name="number" id="select">
                            {
                              [...Array(15)].map((_, index) => (
                                <option key={index + 1} value={index + 1}>
                                  {index + 1}
                                </option>
                              ))
                            }
                          </select>
                        </h4>
                        <h4>Trigger: <p onClick={() => handleUpdateActiveInactive("trigger", !selectedUser.trigger.active) } className="profile-status" style={selectedUser.trigger.active ? { backgroundColor: "#55f355" } : { backgroundColor: "#f36d55" }}>{selectedUser.trigger.active ? "Ativa" : "Inativa"}</p></h4>
                        <h4>Warming: <p onClick={() => handleUpdateActiveInactive("warming", !selectedUser.warming.active) } className="profile-status" style={selectedUser.warming.active ? { backgroundColor: "#55f355" } : { backgroundColor: "#f36d55" }}>{selectedUser.warming.active ? "Ativa" : "Inativa"}</p></h4>
                        <h4>AI: <p onClick={() => handleUpdateActiveInactive("ai", !selectedUser.ai.active) } className="profile-status" style={selectedUser.ai.active ? { backgroundColor: "#55f355" } : { backgroundColor: "#f36d55" }}>{selectedUser.ai.active ? "Ativa" : "Inativa"}</p></h4>
                        <h4>Protocolo: <p onClick={() => handleUpdateActiveInactive("protocolo", !selectedUser.protocolo.active)} className="profile-status" style={selectedUser.protocolo.active ? { backgroundColor: "#55f355" } : { backgroundColor: "#f36d55" }}>{selectedUser.protocolo.active ? "Ativa" : "Inativa"}</p></h4>
                      </div>
                    </div>
                  </div>

                </>
              )
          }
        </div>
      </div>
      <ToastContainer
        stacked
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {modalEditUserOpen && (
        ReactDOM.createPortal(
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Editar Usuário</h2>
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Digite o nome do usuário aqui."
              />
              <div className="modal-buttons">
                <button onClick={confirmNewUsername} className="confirm-button">Confirmar</button>
                <button onClick={closeModalEditUser} className="cancel-button">Cancelar</button>
              </div>
            </div>
          </div>,
          document.body
        )
      )}
      {modalEditpPassOpen && (
        ReactDOM.createPortal(
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Editar Senha</h2>
              <input
                type="text"
                value={passWord}
                onChange={(e) => setPassWord(e.target.value)}
                placeholder="Digite a nova senha do usuário aqui."
              />
              <div className="modal-buttons">
                <button onClick={confirmNewPassWord} className="confirm-button">Confirmar</button>
                <button onClick={closeModalEditPass} className="cancel-button">Cancelar</button>
              </div>
            </div>
          </div>,
          document.body
        )
      )}

    </div>
  )
}

export default UserInto;