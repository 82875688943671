import referLinks from '../../utils/refer.links';
import '../styles/404.css';

const NotFoundPage = () => {
  return (
    <section className="page_404">
      <div className="container">
        <div className="four_zero_four_bg" />
        <div className="contant_box_404">
          <h3>Parece que você está perdido</h3>
          <p>A página que você está procurando não está disponível!</p>
          <a href={referLinks.whapper_home} className="link_404">Voltar para a página inicial</a>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
