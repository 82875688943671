import React from 'react';
import "../screens/styles/dashboard.css"
import { ResponsiveFunnel } from '@nivo/funnel';
import { ApiInterfaces } from "../api/ApiInterfaces";

interface MyResponsiveFunnelProps {
    data: ApiInterfaces.DataPoint[];
}

export const MyResponsiveFunnel: React.FC<MyResponsiveFunnelProps> = ({ data }) => (
    <div className='origem-disparo' style={{ height: '400px' }}>
        <ResponsiveFunnel
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            valueFormat=">d"
            colors={{ scheme: 'yellow_green' }}
            borderWidth={20}
            labelColor={{
                from: 'color',
                modifiers: [
                    ['darker', 3]
                ]
            }}
            beforeSeparatorLength={100}
            beforeSeparatorOffset={20}
            afterSeparatorLength={100}
            afterSeparatorOffset={20}
            currentPartSizeExtension={10}
            currentBorderWidth={40}
            motionConfig="wobbly"
        />
    </div>
);
