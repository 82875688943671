import React from 'react';
import ReactDOM from 'react-dom';
import '../../styles/warning.css';  // Certifique-se de que o arquivo CSS está no mesmo diretório ou ajuste o caminho conforme necessário.
import { ApiInterfaces } from '../../../api/ApiInterfaces';

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Atenção</h2>
        <p>Você realmente deseja apagar esta instância?</p>
        <div className="modal-buttons">
          <button onClick={onConfirm} className="confirm-button">Sim</button>
          <button onClick={onClose} className="cancel-button">Não</button>
        </div>
      </div>
    </div>,
    document.body
  );
}

export default WarningModal;
