import "../styles/home.css";
import "../styles/dashboard.css";
import ReactDOM from 'react-dom';
import Select from 'react-select';
import moment from 'moment-timezone';
import 'boxicons/css/boxicons.min.css';
import { useEffect, useState } from 'react';
import { SiteElements } from "../include/elements";
import { ApiRequests } from '../../api/ApiRequests';
import { toast, ToastContainer } from 'react-toastify';
import { ApiInterfaces } from '../../api/ApiInterfaces';
import { ApiHelpFuntions } from '../../api/ApiHelpFuntions';
import { MyResponsiveFunnel } from "../../utils/nivo.component";

function DashBoardInto() {
  const [users, setUsers] = useState<number>(0); // Lista o número de usuários na plataforma.
  const [whappers, setWhappers] = useState<number>(0); // Número de Whappers Ativos na plataforma. Que suas insatancias ainda não foram finalizadas
  const [messageNumber, setMessageNumber] = useState<number>(0); // Número de mensagens enviadas no mes pelo o whatsapp
  const [billingEstimated, setBillingEstimated] = useState<number>(0); // Dinheiro estimado que a plataforma está gerando.
  const [relatory, setRelatory] = useState<ApiInterfaces.toDashBoardInterfaceRelatory[]>([]); // Relatorio de todas as mesngens enviadas na plataforma
  const [name, setName] = useState<string>(''); // Seta o nome do usuário que se quer filtrar
  const [actionFilter, setActionFilter] = useState<boolean>(false); // Seta o tipo de envio que se quer filtrar
  const [nivoGraf, setNivoGraf] = useState<ApiInterfaces.DataPoint[]>([]); // Nivo 
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false); // Modal de informações das mensagens
  const [messageData, setMessageData] = useState<ApiInterfaces.MessageInfo | undefined>();

  useEffect(() => {
    // Funções de busca na API
    const dashBoardData = async () => {
      const allUsers = await ApiRequests.ListUsers(); // Buscando os usuários na plataforma.
      const allWhappers = await ApiRequests.ListWhappers(); // Busca os whappers na plataforma.
      const allMessages = await ApiRequests.ListMessages(); // Busca por todas as mensagens enviadas na plataforma.

      if (allMessages) setMessageNumber(allMessages.filter(message => moment(message.sendingAt, "DD/MM/YYYY hh:mm:ss").tz("America/Sao_Paulo").month() == moment().tz("America/Sao_Paulo").month() && moment(message.sendingAt, "DD/MM/YYYY hh:mm:ss").tz("America/Sao_Paulo").year() == moment().tz("America/Sao_Paulo").year()).length); // Número de mensagens que a plataforma disparou somente nesse mês
      if (allWhappers) setWhappers(allWhappers.filter(whapper => whapper.terminated == false).length); // Se houver na busca de whappers, então exibit o numero de whappers da platafomra
      if (allUsers) setUsers(allUsers.length); // Se houver na busca esses usuários, então exibir o numero de usuários na plataforma.

      
      if (allUsers && allWhappers && allMessages) {
        setNivoGraf([
          {
            "id": "step_ai",
            "value": allMessages.filter(element => element.plataform.toLowerCase() == "ai").length,
            "label": "AI"
          },
          {
            "id": "step_trigger",
            "value": allMessages.filter(element => element.plataform.toLowerCase() == "trigger").length,
            "label": "Trigger"
          },
          {
            "id": "step_protocolo",
            "value": allMessages.filter(element => element.plataform.toLowerCase() == "protocolo").length,
            "label": "Protocolo"
          },
          {
            "id": "step_normal",
            "value": allMessages.filter(element => element.plataform.toLowerCase() == "normal").length,
            "label": "Normal"
          }
        ]);
        setRelatory(ApiHelpFuntions.extractRelatory(allUsers, allWhappers, allMessages));
        setBillingEstimated((allUsers.length*149.99) + (allWhappers.filter(whapper => whapper.terminated == false).length*59.99) + (allMessages.filter(message => moment(message.sendingAt, "DD/MM/YYYY hh:mm:ss").tz("America/Sao_Paulo").month() == moment().tz("America/Sao_Paulo").month() && moment(message.sendingAt, "DD/MM/YYYY hh:mm:ss").tz("America/Sao_Paulo").year() == moment().tz("America/Sao_Paulo").year()).length*0.09));
      }
    }

    dashBoardData()
    
    // Consulta à API a cada 5 segundos (ajuste o intervalo conforme necessário)
    const intervalId = setInterval(dashBoardData, 8000);

    // Certifique-se de limpar o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []);

  const handleOpenModalMessage = async (whapperKey: string, messageId: number) => {
    const toastInfo = toast.loading("Buscando Mensagem...");

    try {
      const message = await ApiRequests.getMessageInfo(whapperKey, messageId);
    
      if (message) {
        setMessageData(message);
        setIsInfoModalOpen(true);
        toast.done(toastInfo);
      } else {
        toast.update(toastInfo, { render: "Não foi possível localizar a mensagem.", type: "warning", isLoading: false, position: "bottom-right" });
        await new Promise(resolve => setTimeout(resolve, 5000));
        toast.done(toastInfo);
      }
    } catch(err) {
      console.log(err);
      toast.update(toastInfo, { render: "Falha ao efetuar a solicitação!", type: "error", isLoading: false, position: "bottom-right" });
    }
  }

  return (
    <div>
      {/* <!-- Insights --> */}
      <ul className="insights">
        <li>
          <i className='bx bx-user'></i>
          <span className="info">
            <h3 onClick={async () => await ApiRequests.getToken()}>
              {users}
            </h3>
            <p>Usuários na Plataforma</p>
          </span>
        </li>
        <li><i className='bx bxl-whatsapp' ></i>
          <span className="info">
            <h3>
              {whappers}
            </h3>
            <p>Whappers Habilitados</p>
          </span>
        </li>
        <li><i className='bx bx-send'></i>
          <span className="info">
            <h3>
              {messageNumber}
            </h3>
            <p>Envios do Mês</p>
          </span>
        </li>
        <li><i className='bx bx-dollar-circle'></i>
          <span className="info">
            <h3>
              R$ {billingEstimated.toFixed(2)}
            </h3>
            <p>Estimativa Mensal</p>
          </span>
        </li>
      </ul>
      {/* <!-- End of Insights --> */}
      <div className="bottom-data">
        <div className="orders">
          <div className="header">
            <i className='bx bx-receipt'></i>
            <h3>Ações Recentes</h3>
            {
              actionFilter 
                &&
              <div className="select-user">
                <Select
                  options={relatory.filter((element, index, self) => self.findIndex(e => e.username === element.username) === index).map((element) => ({ value: element.username, label: element.username }))}
                  menuPosition="fixed"
                  onChange={(selected) => setName(selected?.label || '')}
                  isClearable={true}
                  placeholder="Selecione um usuário!"
                  styles={SiteElements.isDark ? {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #25252C', // Defina a borda que você deseja
                    boxShadow: 'none', // Remove a sombra de foco
                    background: '#25252C', // Define a cor de fundo para o tema escuro
                    color: 'white', // Define a cor do texto para o tema escuro
                    borderColor: 'gray', // Define a cor da borda
                    '&:hover': {
                      borderColor: 'white', // Define a cor da borda quando o cursor está sobre o controle
                    },
                    // Outros estilos de controle, se necessário
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color:"grey", // Cor do texto para o tema escuro
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: '#25252C', // Cor de fundo do menu suspenso
                    color: 'white', // Cor do texto no menu suspenso
                    '&::-webkit-scrollbar': {
                      width: '8px', // Largura da caixa de rolagem
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray', // Cor da alça da caixa de rolagem
                    },
                    // Outros estilos de menu suspenso, se necessário
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#181A1E' : '#25252C' // Define a cor de fundo ao passar o mouse sobre a opção
                  })} : {}}
                />
              </div>
            }
            <i className='bx bx-filter' onClick={() => {setActionFilter(!actionFilter)}}></i>
          </div>
          <table>
            <thead>
              <tr>
                <th>Usuário</th>
                <th>Data</th>
                <th>Origem</th>
              </tr>
            </thead>
            <tbody>
              
              {
                relatory.filter((element) => name.length ? element.username.toLowerCase() == name.toLocaleLowerCase() : element).map((element, index) => (<tr onClick={() => handleOpenModalMessage(element.whapperKey, element.messageId)}>
                  <td key={index}>
                    <img src={element.image}></img>
                    <p>{element.username}</p>
                  </td>
                  <td>{element.date}</td>
                  <td><span {...{className: `status ${element.code}`}}>{element.plataform}</span></td>
                </tr>))
              }

            </tbody>
          </table>
        </div>

        <div className="reminders">
          <div className="header">
            <i className='bx bx-bar-chart-alt-2' ></i>
            <h3>Origem/Disparo</h3>
          </div>
          {
            nivoGraf.length 
              &&
            <MyResponsiveFunnel data={nivoGraf} />
          }
        </div>
      </div>
      {
        isInfoModalOpen 
          &&
        messageData
          &&
        ReactDOM.createPortal(
          <div className="info-modal-overlay">
            <div className="info-modal-content">
              <h2>Informações da Mensagem</h2>
              <ul className="info-list">
                <li><strong>WhapperKey:</strong> {messageData.whapperKey}</li>
                <li><strong>Origem:</strong> {messageData.origin}</li>
                <li><strong>Destino:</strong> {messageData.destiny}</li>
                <li><strong>Enviado:</strong> {messageData.sendingAt}</li>
                <li><strong>É Carga:</strong> {messageData.isCharge ? 'Sim' : 'Não'}</li>
                {messageData.isCharge && <li><strong>Código da Carga:</strong> {messageData.haveCode}</li>}
                <li><strong>Respondido:</strong> {messageData.response ? 'Sim' : 'Não'}</li>
                <li><strong>Plataforma:</strong> {messageData.plataform}</li>
                <li><strong>Status:</strong> {messageData.status}</li>
                <li><strong>Dados:</strong> {messageData.message}</li>
              </ul>
              <div className="info-modal-buttons">
                <button onClick={() => {setMessageData(undefined); setIsInfoModalOpen(false)}} className="close-button">Fechar</button>
              </div>
            </div>
          </div>,
          document.body
        )
      }
      <ToastContainer
        stacked
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default DashBoardInto;