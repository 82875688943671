import React, { useState } from 'react';
import '../styles/analytics.css';

const Analytics: React.FC = () => {
  return (
    <div className="analytics-container">
      
    </div>
  );
};

export default Analytics;
