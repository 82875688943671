// Importações importantes para o funcionamento do arquivo
import moment from "moment-timezone";

/**
 * **Função que revela e entrega uma string com a data especifica solicitada.**
 * 
 * @param {("hh:mm" | "hh:mm:ss" | "DD/MM/YYYY" | "DD de MM de YYYY" | "DD de MM de YYYY às hh:mm" | "day, DD de MM de YYYY às hh:mm")} type - O tipo data que será criada.
*/
export function GenerateDate(type: ("DD/MM/YYYY hh:mm:ss" | "hh:mm" | "hh:mm:ss" | "DD/MM/YYYY" | "DD de MM de YYYY" | "DD de MM de YYYY às hh:mm" | "day, DD de MM de YYYY às hh:mm")): string {
  // Criando a variavel que será responsavel por guardar o valor mutavel da data proposta.
  let returns_date: string;

  // Criando o switch que fará essa verificação e criará o solicitado.
  switch (type) {
    case "DD de MM de YYYY":
      returns_date = moment().tz('America/Sao_Paulo').locale("pt-br").format("LL");
      break;
    case "DD de MM de YYYY às hh:mm":
      returns_date = moment().tz('America/Sao_Paulo').locale("pt-br").format("LLL");
      break;
    case "DD/MM/YYYY":
      returns_date = moment().tz('America/Sao_Paulo').locale("pt-br").format("L");
      break;
    case "day, DD de MM de YYYY às hh:mm":
      returns_date = moment().tz('America/Sao_Paulo').locale("pt-br").format("LLLL");
      break;
    case "hh:mm":
      returns_date = moment().tz('America/Sao_Paulo').locale("pt-br").format("LT");
      break;
    case "hh:mm:ss": 
      returns_date = moment().tz('America/Sao_Paulo').locale("pt-br").format("LTS");
      break;
    case "DD/MM/YYYY hh:mm:ss": 
      returns_date = moment().tz('America/Sao_Paulo').locale("pt-br").format("LTS");
      break;
    default:
      returns_date = "Error: new response is not allowed";
      break;
  }

  return returns_date;
}