import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './screens/Home';
import NotFoundPage from './screens/components/404';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ExpiredCredentials from './screens/components/expired';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="/expired" element={<ExpiredCredentials />} />
    </Routes>
  </BrowserRouter>
);


/* root.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>
); */