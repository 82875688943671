import "../styles/home.css";
import "../styles/users.css"; 
import "../styles/dashboard.css";
import "../styles/connections.css"
import "react-toastify/dist/ReactToastify.css";
import QrCode from "qrcode";
import ReactDOM from 'react-dom';
import image from "../images/whapper.png";
import { useEffect, useState } from "react";
import { ApiRequests } from "../../api/ApiRequests";
import { ApiInterfaces } from "../../api/ApiInterfaces";
import { toast, ToastContainer } from 'react-toastify';
import referLinks from "../../utils/refer.links";
import WarningModal from "./smallComponents/WarningModal";

function ConnectionsInto() {
  const [me, setMe] = useState<ApiInterfaces.PublicInfo>();
  const [selectedWhapper, setSelectedWhapper] = useState<ApiInterfaces.ListMyWhappers>();
  const [myWhappers, setMyWhappers] = useState<ApiInterfaces.ListMyWhappers[]>([]);
  const [disableConnect, setDisableConnect] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState<string | null>();
  const [numberTest, setNumberTest] = useState<string>("");
  const [messageTest, setMessageTest] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [instanceName, setInstanceName] = useState('');
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{ key: string, name: string } | null>(null); 
  const [isModalWarningOpen, setIsModalWarningOpen] = useState(false);

  useEffect(() => {
    // Funções de busca na API
    const UsersData = async () => {
      const meUser = await ApiRequests.GetMyPublicInfo(); // Buscando os usuários na plataforma.
      const allMyWhappers = await ApiRequests.ListMyWhappers(); // Buscando os Usuários do cliente na plataforma.

      if (meUser && allMyWhappers) {
        setMe(meUser);
        setMyWhappers(allMyWhappers);
      }
    }

    UsersData()

    // Consulta à API a cada 5 segundos (ajuste o intervalo conforme necessário)
    const intervalId = setInterval(UsersData, 8000);

    // Certifique-se de limpar o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []);

  const handleOptionChange = (optionName: {key: string, name: string}) => {
    setSelectedOption(optionName);
  };

  const handleNewOk = async (remove?: true) => {
    try {
      if (!selectedWhapper) throw new Error("Não foi possível setar a redundância!");

      const redundancy = await ApiRequests.setRedundancy(selectedWhapper.key, remove ? undefined : selectedOption?.key);

      if (redundancy) {
        toast.success(remove ? `${selectedWhapper.name} agora não tem mais uma redundância.`: `${selectedOption?.name} agora é redundância de ${selectedWhapper.name}!`);
      } else {
        toast.error("Falha na tentativa de localizar a instância");
      }
    } catch (err) {
      console.log(err);
      toast.warn("Não foi possível setar a redundância!")
    }
    closeNewModal();
  };

  const closeNewModal = () => {
    setIsNewModalOpen(false);
    setSelectedOption(null);
  };

  const closeModal = () => setIsModalOpen(false);

  const createInstance = async () => {
    if (myWhappers.find(instance => instance.name == instanceName)) return toast.warn("Já existe uma instância com esse nome!");

    if (instanceName.length == 0) return toast.warn("Digite um nome para a nova instância!");

    try {
      const instance = await ApiRequests.createInstance(instanceName);

      if (instance.info.uniqueKey) {
        toast.success(`Sucesso ao criar a Instância ${instance.info.uniqueKey}!`);
        setIsModalOpen(false);

        const reqAllMyWhappers = await ApiRequests.ListMyWhappers();

        if (reqAllMyWhappers) {
          setMyWhappers(reqAllMyWhappers);
          setSelectedWhapper(reqAllMyWhappers.find(whapper => whapper.key == instance.info.uniqueKey));
        } else return toast("Carregando...");
      } else return toast.warn("Falha ao receber a instância, aguarde...");
    } catch(err) {
      console.log(err);
      return toast.warn("Não foi possível criar a instância. Tente novamente!");
    }
  }

  const openCreationModal = () => {
    if (!me) return toast.error("(500) Erro interno no servidor!");

    console.log(myWhappers);

    if (me.cConnections == myWhappers.filter(instance => instance.active && instance.active !== "Instance Finished").length) return toast.warning(/* "Limite de Instâncias atingido!" */
      (
        <div>
          Limite de Instâncias atingido. Fazer <a style={{ color: "#19D257"}} href={referLinks.whapper_home} target="_blank" rel="noopener noreferrer">Upgrade</a>
        </ div>
      )
    );

    setIsModalOpen(true);
  }

  const sendMessage = async () => {
    if (!selectedWhapper) return toast.warn("Selecione um Whapper!");


    if (!selectedWhapper.active) return toast.warn("Whapper está desconectado!");

    if (!messageTest.length || !numberTest.length) return toast.warn("Preencha os campos de teste antes!");

    try {
      await ApiRequests.SendMessage(selectedWhapper.key, messageTest, numberTest).then(info => 
        {
          if (info) {
            toast.success("Mensagem Disparada!");
          } else {
            toast.error("Falha no envio. Tente novamete.");
          }
        }
      );
    } catch(err) {
      return toast.error("(500) Erro no servidor.");
    }
  }

  const disconnectWhapper = async () => {
    if (!selectedWhapper) return toast.warn("Selecione um Whapper!");


    if (!selectedWhapper.active) return toast.warn("Whapper já está desconectado!");

    try {
      await ApiRequests.RequestDisconnectWhapper(selectedWhapper.key).then(async info => 
        {
          if (info) {
            toast.success("A Instância foi desconectada!");
            setDisableConnect(false);

            const reqAllMyWhappers = await ApiRequests.ListMyWhappers();

            if (reqAllMyWhappers) {
              setMyWhappers(reqAllMyWhappers);
              setSelectedWhapper(reqAllMyWhappers.find(whapper => whapper.key == selectedWhapper.key));
            } else return toast("Carregando...");
          } else {
            toast.error("Falha na desconexão. Tente novamente.");
          }
        }
      );
    } catch(err) {
      return toast.error("(500) Erro no servidor.");
    }
  }

  const connectWhapper = async () => {
    if (!selectedWhapper) return toast.warn("Selecione um Whapper!");
    

    if (selectedWhapper.active) return toast.warn("Whapper já está conectado!");
    
    const toastInfo = toast.loading("Conectando...");

    setDisableConnect(true);

    try {
      const requestConnection = async () => {
        const connection = await ApiRequests.RequestConnectWhapper(selectedWhapper.key);
        const reqAllMyWhappers = await ApiRequests.ListMyWhappers();

        if (reqAllMyWhappers) {
          setMyWhappers(reqAllMyWhappers);
          setSelectedWhapper(reqAllMyWhappers.find(whapper => whapper.key == selectedWhapper.key));
        }

        if (typeof connection == "string") {
          toast.update(toastInfo, { render: "Qrcode Gerado! Leia-o.", type: "warning", isLoading: false, position: "bottom-right" });
          let qrcodeImageUrl = await QrCode.toDataURL(connection);
          setQrCodeImage(qrcodeImageUrl);

          await new Promise(resolve => setTimeout(resolve, 10000));

          await requestConnection();
        } else {
          toast.update(toastInfo, { render: "Sucesso ao Conectar a Instância", type: "success", isLoading: false, position: "bottom-right" });
          setQrCodeImage(null);
          setDisableConnect(false);
        }

        await new Promise(resolve => setTimeout(resolve, 5000));
        toast.done(toastInfo);
      }

      await requestConnection();
    } catch(err) {
      console.log(err);
      toast.update(toastInfo, { render: "Falha ao efetuar a solicitação!", type: "error", isLoading: false, position: "bottom-right" });
    }
  }

  const handleRemoveWhapper = async () => {
    try {
      if (!selectedWhapper) return toast.warning("É preciso ter uma instância selecionada para realizar essa ação.");

      if (typeof selectedWhapper.active == "boolean" && selectedWhapper.active) return toast.warning("A instância presisa estar desconectada para efetuar essa operação.");

      const remove = await ApiRequests.removeWhapper(selectedWhapper.key);

      if (remove) {
         toast.success(`A instância ${selectedWhapper.name} foi removida com sucesso!`);
      } else  {
        toast.warning("Não foi possível remover a instância no momentos. Tente novamente mais tarde.");
      }

      setIsModalWarningOpen(false);
      const reqAllMyWhappers = await ApiRequests.ListMyWhappers();

      if (reqAllMyWhappers) {
        setMyWhappers(reqAllMyWhappers);
        setSelectedWhapper(reqAllMyWhappers.find(whapper => whapper.key == selectedWhapper.key));
      }
    } catch(err) {
      console.log(err);
      toast.error("(500) Erro interno no servidor!");
    }
  } 

  return (
    <div className="connections-data">
      <div className="connection-user-page-left">
        <div className="left-box-billing">
          <p className="boxClip">
            <i className='bx bx-paperclip'></i>
          </p>
          <div>
            <h4>{ !me ? "Servidor com Problemas" : me.accountType == "UNLIMITED" ? `Serviço Ilimitado` : `${me.accountBilling} / R$ ${(me.accountBilling*0.09).toFixed(2)}`}</h4>
            <p>Disparos Restantes / Billing Associado</p>
          </div>
        </div>

        <div onClick={() => { if (selectedWhapper && typeof selectedWhapper.active !== "string") setIsNewModalOpen(true); if (selectedWhapper && typeof selectedWhapper.active == "string") toast.warn("Essa instância foi finalizada!"); }} style={{ cursor: selectedWhapper ? "pointer" : "unset" }} className="left-box-selected-whapper">
          <p className="signal">
            <i className='bx bx-signal-5'></i>
          </p>
          <div>
            {
              selectedWhapper 
                ?
              (
                <div>
                  <h4>{selectedWhapper.name}</h4>
                    <p>{selectedWhapper.redundancy ? `Redundância ${myWhappers.find(whapper => whapper.key == selectedWhapper.redundancy)?.name}`  : "Sem redundância ativa"}</p>
                </div>
              ) 
                :
              (
                <div>
                  <h3>Selecione um Whapper...</h3>
                </div>
              )
            }
          </div>
        </div>

        <div className="left-box-my-instances">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4 className="instances"><i className='bx bx-rocket'></i> Minhas Instâncias ({myWhappers.filter(whapper => (typeof whapper.active !== "string")).length}/{me?.cConnections})</h4>
            <div className="new-instance" onClick={openCreationModal}>+ Nova</div>
          </div>
          <ul>
            {
              myWhappers.map(whapper => 
                (
                <li onClick={() => selectedWhapper == whapper ? setSelectedWhapper(undefined) : setSelectedWhapper(whapper)}><span className={whapper.active == true ? "green" : whapper.status == "Instance Finished" ? "yellow" : "red"}></span> {whapper.name} <br />#{whapper.key}</li>
                )
              )
            }
          </ul>
        </div>
      </div>
      <div className={selectedWhapper ? "connection-user-page-right" : "connection-user-page-right-no-selected"}>
        {
          selectedWhapper
            ? 
          (
            <div>
                <div className="info">
                  <h4 className="instances"><i className='bx bxs-bookmark-alt'></i> Instância - <a className={selectedWhapper.active == true ? "green" : selectedWhapper.status == "Instance Finished" ? "yellow" : "red"}>{selectedWhapper.name}</a></h4>

                  <ul className="margin">
                    <li><b>° Chave:</b> #{selectedWhapper.key}</li>
                    <li><b>° Status:</b> {selectedWhapper.status}</li>
                    <li><b>° Número Conectado:</b> {selectedWhapper.connectedNumber}</li>
                    {/* <li><b>° Último uso:</b> Não encontrado</li> */}
                  </ul>
                </div>
                <div className="actions">
                  <button style={typeof selectedWhapper.active == "string" ? { cursor: "not-allowed" } : { cursor: "pointer" }} onClick={() =>  {
                    if (typeof selectedWhapper.active == "boolean" && selectedWhapper.active) {
                      return disconnectWhapper();
                    } 
                    if (typeof selectedWhapper.active == "boolean" && !selectedWhapper.active) {
                      return connectWhapper();
                    }
                    else return toast.warn("Impossível realizar essa ação.");
                  }
                  } disabled={typeof selectedWhapper.active == "string" || disableConnect ? true : false}><i className={typeof selectedWhapper.active == "boolean" && selectedWhapper.active ? "bx bx-exit" : typeof selectedWhapper.active == "string" ? "bx bxs-error" : "bx bx-run"}></i> {typeof selectedWhapper.active == "boolean" && selectedWhapper.active ? "Desconectar" : typeof selectedWhapper.active == "string" ? "Whapper Terminado" : "Conectar"}</button>
                  <button><i className="bx bx-download"></i> Extrair Relatório</button>
                  <button onClick={() => setIsModalWarningOpen(true)} style={typeof selectedWhapper.active == "string" ? { cursor: "not-allowed" } : { cursor: "pointer" }} disabled={typeof selectedWhapper.active == "string" ? true : false}><i className="bx bx-trash"></i> {typeof selectedWhapper.active == "string" ? "Instância Terminada" : "Remover Whapper"}</button>
                  <div className="inputs">
                    <input type="text" onChange={value => setNumberTest(value.target.value)} value={numberTest} placeholder="99 98888-7777" />
                    <input type="text" onChange={value => setMessageTest(value.target.value)} value={messageTest} placeholder="Digite algo para teste" />
                    <button onClick={sendMessage} style={typeof selectedWhapper.active == "string" ? { cursor: "not-allowed" } : typeof selectedWhapper.active == "boolean" && selectedWhapper.active ? { cursor: "pointer" } : { cursor: "not-allowed" } } disabled={typeof selectedWhapper.active == "string" ? true : false}><i className='bx bxs-paper-plane'></i>{typeof selectedWhapper.active == "boolean" && selectedWhapper.active ? "Disparar Teste" : typeof selectedWhapper.active == "string" ? "Disparar Teste (Indisponível)" : "Disparar Teste (Indisponível)"}</button>
                  </div>
                </div>
                <div className="qr-code">
                  <p>Apontar a Câmera do seu WhatsApp</p>
                  <img src={qrCodeImage ||image} alt="QR Code Whapper" />
                </div>
            </div>
          )
            : 
          (
            <h3 style={{fontSize: "25px"}}>Selecione um Whapper...</h3>
          )
        }
      </div>
      <ToastContainer
        stacked
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isModalOpen && (
        ReactDOM.createPortal(
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Como será chamada?</h2>
              <input
                type="text"
                value={instanceName}
                onChange={(e) => setInstanceName(e.target.value)}
                placeholder="Digite o nome da instância aqui."
              />
              <div className="modal-buttons">
                <button onClick={createInstance} className="create-button">Criar Instância</button>
                <button onClick={closeModal} className="close-button">Fechar</button>
              </div>
            </div>
          </div>,
          document.body
        )
      )}

      {isNewModalOpen &&
        ReactDOM.createPortal(
          <div className="new-modal-overlay">
            <div className="new-modal-content">
              <h2>Escolha uma Redundância para {selectedWhapper?.name}</h2>
              <ul className="option-list">
                {myWhappers.filter(whapper => (typeof whapper.active !== "string") && whapper.key !== selectedWhapper?.key).map((option) => (
                  <li key={option.key} className="option-item">
                    <input
                      type="radio"
                      id={option.key.toString()}
                      name="option"
                      value={option.name}
                      checked={selectedOption?.name === option.name}
                      onChange={() => handleOptionChange({name: option.name, key: option.key })}
                    />
                    <label htmlFor={option.key.toString()}>{option.name}</label>
                  </li>
                ))}
              </ul>
              <div className="new-modal-buttons">
                <button onClick={() => handleNewOk()} className="confirm-button" disabled={!selectedOption}>Confirmar</button>
                {selectedWhapper?.redundancy && (
                  <button onClick={() => handleNewOk(true)} className="redundancy-button">Remover </button>
                )}
                <button onClick={closeNewModal} className="cancel-button">Cancelar</button>
              </div>
            </div>
          </div>,
          document.body
        )}
      <WarningModal
        isOpen={isModalWarningOpen}
        onClose={() => setIsModalWarningOpen(false)}
        onConfirm={handleRemoveWhapper}
      />
    </div>
  );
}

export default ConnectionsInto;