import { ApiInterfaces } from "./ApiInterfaces";
import image from "../screens/images/image.jpeg";
import moment from "moment-timezone";

export class ApiHelpFuntions {
  public static extractRelatory(allUsers: ApiInterfaces.ListUsers[], allWhappers: ApiInterfaces.ListWhappers[], allSendings: ApiInterfaces.ListMessages[]): ApiInterfaces.toDashBoardInterfaceRelatory[] {

    const relatory: ApiInterfaces.toDashBoardInterfaceRelatory[] = [];
    
    for (let i = 0; i < allSendings.length; ++i) {
      const message = allSendings[i];
      const whapper = allWhappers.find(w => w.whapper_key == message.whapperKey)

      if (!whapper) continue;

      
      const user = allUsers.find(u => u.email == whapper.owner_email);

      if (!user) continue;

      relatory.push(
        {
          image,
          username: user.username,
          messageId: message.id,
          whapperKey: whapper.whapper_key,
          date: message.sendingAt,
          code: message.plataform == "NORMAL" ? "completed" : message.plataform == "TRIGGER" ? "pending" : "process",
          plataform: message.plataform.charAt(0).toUpperCase() + message.plataform.slice(1).toLowerCase()
        }
      );
    }

    return relatory.reverse();
  }
  public static formatDate(date: any): string {
    return moment(date).tz("America/Sao_Paulo").format("DD/MM/YYYY hh:mm:ss");
  }
}