// Importações
import axios from "axios";
import moment from "moment-timezone";
import { GenerateDate } from "../utils/formated.date";
import { ApiInterfaces } from "./ApiInterfaces";
import { CONNECTION } from "../screens/include/connection.enum";
import referLinks from "../utils/refer.links";
import { rejects } from "assert";

// Classe que ficará responsável por estar efetuando todas as solicitações do site para o backend
export class ApiRequests {
  /** Rota de Solicitação para a API */
  private static apiRoute: string = referLinks.whapper_api

  private static credentials: {date: string, token:string}; // Variavel momentanea que guardará o token;
  private static token: string = ""; // Momentâneo também, apenas enquanto estiver em desenvolvimento.

  public static setToken(refresh_token: string) {
    this.token = refresh_token;
  }

  public static getAvailableToken() {
    return this.token;
  }

  /**
   * getToken
   * @returns undefined se a resposta não for a altura, ou string o token de usuário!
   */
  public static async getToken(): Promise<undefined | string> {
    return new Promise(async (resolve, reject) => 
      {
        if (this.credentials) {
          let dateTime = moment(this.credentials.date, "DD/MM/YYYY hh:mm:ss");
          dateTime = dateTime.tz("America/Sao_Paulo");
          const dateNow = moment().tz("America/Sao_Paulo");

          if (moment.duration(dateNow.diff(dateTime)).asHours() < 2) return resolve(this.credentials.token);
        }

        try {
          const response = await axios.get(`${this.apiRoute}/info`, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth) {
            this.credentials = {
              date: GenerateDate("DD/MM/YYYY hh:mm:ss"),
              token: this.token.toString()
            }

            return resolve(this.token);
          } else {
            return resolve(undefined);
          }
        } catch (err) {
          console.log(err);
          return resolve(undefined);
        }
      }
    );
  }

  /**
   * setRedundancy
   * @param from whapper que pode estar desconectado
   * @param to whapper que irá assumir o desconectado
   * @returns booleano que diz se foi setada a redundancia ou não
   */
  public static async setRedundancy(from: string, to?: string): Promise<undefined | boolean> {
    return new Promise(async (resolve, rejects) => 
      {
        try {
          const token = await this.getToken();

          if (!token) { this.setToken(""); return window.location.href = "/expired"; }

          const response = await axios.post(`${this.apiRoute}/whapper/set-redundancy`, { from, to }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth) {
            return resolve(true);
          } else throw new Error("Não foi possível setar a redundancia!");
        } catch(err) {
          console.log(err);
          rejects(err);
        }
      }
    );
  }

  /**
   * ListMyCommands
   * @returns Listagem com todos os comandos disponíveis para os whappers desse cliente
   */
  public static async ListMyCommands(): Promise<ApiInterfaces.WhapperCommands[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.get(`${this.apiRoute}/whapper/commands/list`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth && response.info) {
          return resolve(response.info);
        } else {
          return resolve([]);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * ListMyCharges
   * @returns Listagem com todos as cargas disponíveis para esse cliente
   */
  public static async ListMyCharges(): Promise<ApiInterfaces.WhapperCharge> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.get(`${this.apiRoute}/whapper/trigger/list-charges`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth && response.info) {
          return resolve(response.info);
        } else {
          return resolve({});
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * ListMyForms
   * @returns Listagem de todas as formas criadas pelo cliente
   */
  public static async ListMyForms(): Promise<ApiInterfaces.WhapperForms[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.get(`${this.apiRoute}/whapper/list-forms`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth && response.info) {
          return resolve(response.info);
        } else {
          return resolve([]);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * CreateNewCommand
   * @param key Chave do Whapepr onde será inserido o botão
   * @param firstMessage primeira mensagem, o acionamento do botão
   * @param message mensagem de devolução
   * @returns boolano
   */
  public static async CreateNewCommand(key: string, firstMessage: string, message: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        try {
          const token = await this.getToken();

          if (!token) { this.setToken(""); return window.location.href = "/expired"; }

          const response = await axios.post(`${this.apiRoute}/whapper/commands/create`, {key, firstMessage, message}, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }

  /**
   * StartCharge
   * @param code codigo da forma
   * @param whapperKey Whapper que irá disparar a carga
   * @returns boolano
   */
  public static async StartCharge(code: string, whapperKey: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/trigger/start-trigger`, { code, whapperKey }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * StartCharge
   * @param code codigo da forma
   * @returns boolano
   */
  public static async DeleteCharge(code: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/trigger/delete-charge`, { code }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * CreateNewCharge
   * @param spreadsheet Id da Planilia compartilhada
   * @param formCode Id da forma de disparo
   * @param tab Nome da aba que será disparada
   * @param time Tempo entre os envios
   * @param name Nome da Carga
   * @returns boolano
   */
  public static async CreateNewCharge(spreadsheet: string, formCode: string, tab: string, time: number, name: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/trigger/upload-spreadsheets`, { spreadsheet, formCode, tab, time, name }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * CreateNewForm
   * @param message Mensagem da forma selecionada!
   * @returns string | false
   */
  public static async CreateNewForm(message: string): Promise<string | false> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/set-form`, { message }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);
        
        if (response.auth) {
          return resolve(response.info.formCode);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * SendEmail
   * @param email email para compartilhar com a planilia
   * @returns boolean
   */
  public static async SendEmail(email: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/trigger/share-spreadsheet`, { email }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * RemoveCommand
   * @param id id do comando que será deletado
   * @returns booleano
   */
  public static async RemoveCommand(id: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/commands/delete`, { id }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * RemoveForm
   * @param code Codigo da forma que será deletada
   * @returns booleano
   */
  public static async RemoveForm(code: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/remove-form`, { code }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * UpdateCommand
   * 
   * @param id id do comando já anteriormente criado
   * @param key chave da instancia responsável por esse comando
   * @param firstMessage primeira mensagem de acionamento do comando
   * @param message mensagem de devolução
   * @returns booleano
   */
  public static async UpdateCommand(id: number, firstMessage: string, message: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/commands/update`, { id, firstMessage, message }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * UpdateStatusService
   * 
   * @param id id do comando já anteriormente criado
   * @param type tipo de serviço que será editado
   * @param status Novo status do serviço
   * @returns booleano
   */
  public static async UpdateStatusService(id: number, type: string, status: boolean): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/admin/plataform/edit-user`, { id, type, status }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }


  /**
   * UpdateForm
   * @param code Codigo da forma a ser atualizada
   * @param message mensagem da forma
   * @returns booleano
   */
  public static async UpdateForm(code: string, message: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/edit-form`, { code, message }, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    }
    );
  }

  /**
   * LogoutAccount
   * @returns retorna a desconexão da autenticação do usuário
   */
  public static async LogoutAccount(): Promise<undefined | boolean> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const response = await axios.post(`${this.apiRoute}/logout`, {}, { headers: { 'authorization': this.token, 'Content-Type': "application/json" } }).then(response => response.data);

          console.log(response);

          if (response.auth) {
            return resolve(true);
          } else {
            return resolve(undefined);
          }
        } catch(err) {
          return reject(err);
        }
      }
    );
  }

  /**
   * ListUsers
   * @returns Retorna uma lista de usuários apenas para quando adminsitradores fazem a solicitação evitando agentes externos.
   */
  public static async ListUsers(): Promise<undefined | ApiInterfaces.ListUsers[]> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();
  
          if (!token) { this.setToken(""); return window.location.href = "/expired"; }
       
          const response = await axios.get(`${this.apiRoute}/admin/list-users`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && response?.allUsersNew) {
            return resolve(response.allUsersNew);
          } else {
            return resolve(undefined);
          }
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }

  /**
   * ListWhappers
   * @returns Retorna a Lista de Whappers que já passaram pela plataforma, apenas disponível a administradores, criptografado
   */
  public static async ListWhappers(): Promise<undefined | ApiInterfaces.ListWhappers[]> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();
    
          if (!token) { this.setToken(""); return window.location.href = "/expired";}
          
          const response = await axios.get(`${this.apiRoute}/admin/list-whappers`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && response?.toResponse) {
            return resolve(response.toResponse);
          } else {
            return resolve(undefined);
          }
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }

  /**
   * ListMyWhappers
   * @returns Retorna uma lista com todos os whappers cadastrados do cliente.
   */
  public static async ListMyWhappers(): Promise<ApiInterfaces.ListMyWhappers[] | undefined> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();
    
          if (!token) { this.setToken(""); return window.location.href = "/expired";}

          const response = await axios.get(`${this.apiRoute}/whapper/list-whappers`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && response?.connections) {
            return resolve(response.connections);
          } else {
            return resolve(undefined);
          }
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }


  /**
   * GetMyPublicInfo
   * @returns Retorna uma breve lista das informações pessoais do usuário dentro da platafomra
   */
  public static async GetMyPublicInfo(): Promise<undefined | ApiInterfaces.PublicInfo> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();

          if (!token) { this.setToken(""); return window.location.href = "/expired";}

          const response = await axios.get(`${this.apiRoute}/info`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && response?.info) {
            return resolve(response.info);
          } else {
            return resolve(undefined);
          }
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }

  /**
   * GetMyPublicInfo
   * @returns Retorna uma breve lista das informações pessoais do usuário dentro da platafomra
   */
  public static async GetMyActiveServices(): Promise<undefined | ApiInterfaces.ActiveServices> {
    return new Promise(async (resolve, reject) => {
        try {
          const token = await this.getToken();

          if (!token) { this.setToken(""); return window.location.href = "/expired"; }

          const response = await axios.get(`${this.apiRoute}/info`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && response) {
            return resolve(response);
          } else {
            return resolve(undefined);
          }
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }


  /**
   * ListMessagesByKey
   * @param key chave do robo que será consultada
   * @returns Listagem de mensagens
   */
  /* public static async ListMessagesByKey(key: string): Promise<undefined | ApiInterfaces.ListMessages[]> {
    return new Promise(async (resolve, reject) => 
      {
        try {

        } catch(err) {
          reject(err);
        }
      }
    );
  } */

  /**
   * createInstance   
   * @param name nome da instancia a ser criada!
   * @returns retorna as informações da instancia criada
   */
  public static async createInstance(name: string): Promise<ApiInterfaces.ConnectionCreated> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();

          if (!token) { this.setToken(""); return window.location.href = "/expired";}

          const response = await axios.post(`${this.apiRoute}/whapper/create-connection`, { name }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth) {
            return resolve(response);
          } else throw new Error("Falha na Autenticação");
        } catch(err) {
          reject(err);
        }
      }
    );
  }

  /**
   * getQrConnection
   * @param key Chave da instancia a qual deseja-se consultar o qrcode
   * @returns retorna as informações do qrcode
   */
  public static async getQrConnection(key: string): Promise<undefined | ApiInterfaces.QrCodeEndpoint> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();

          if (!token) { this.setToken(""); return window.location.href = "/expired";}

          const response = await axios.post(`${this.apiRoute}/whapper/get-qrcode`, { key }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth) {
            return resolve(response);
          } else throw new Error("Falha na Autenticação");
        } catch (err) {
          reject(err);
        }
      }
    );
  }

  /**
   * Request Connect
   * @param key chave do whapper que será conectado a plataforma
   * @returns boolean ou undefined
   */
  public static async RequestConnectWhapper(key: string): Promise<string | true> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired";}

        const response = await axios.post(`${this.apiRoute}/whapper/start-instance`, { key }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          const qrCodeConnection = async (): Promise<string | true> => {
            const qrCodeInfo = await this.getQrConnection(key);

            await new Promise(resolve => setTimeout(resolve, 2000));

            if (qrCodeInfo && qrCodeInfo.auth) {
              switch (qrCodeInfo.info) {
                case CONNECTION.LOADING:
                  return await qrCodeConnection();
                case CONNECTION.CONNECTED:
                  return true;
                case CONNECTION.QRCODE:
                  return qrCodeInfo.qrcode as string;
                default:
                  throw new Error("Serviço de Conexão Indisponível");
              }
            } else throw new Error("Falha ao Receber informações do Qrcode");
          }

          const qrCodeResponse = await qrCodeConnection();

          resolve(qrCodeResponse);
        } else throw new Error("Falha na Autenticação");
      } catch (err) {
        console.log(err);
        reject(err);
      }
    }
    );
  }

  /**
   * SendMessage
   * @param key chave da instancia
   * @param message mensagem que deseja-se ser inviada 
   * @param destiny destino para o qual a instancia irá manda a mensagem
   * @returns booleano que diz se a mensagem foi enviada ou nao
   */
  public static async SendMessage(key: string, message: string, destiny: string): Promise<boolean | undefined> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();

          if (!token) resolve(false);

          const response = await axios.post(`${this.apiRoute}/whapper/send-message`, { key, destiny, message }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && response.send) {
            return resolve(true);
          } else return resolve(false);
        } catch(err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }

  /**
   * RequestDisconnectWhapper
   * @param key Chave do whapper que será desconectado
   * @returns retorna um booleano informando a conexão do robo foi ativa
   */
  public static async RequestDisconnectWhapper(key: string): Promise<undefined | boolean> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();

          if (!token) return resolve(false);

          const response = await axios.post(`${this.apiRoute}/whapper/disconnect-whapper`, { key }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && !response.info) {
            return resolve(true);
          } else return resolve(false);
        } catch(err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }

  /**
   * ListMessages
   * @returns Retorna um array com todas as mensagens registradas na platafornma
   */
  public static async ListMessages(): Promise<undefined | ApiInterfaces.ListMessages[]> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();
    
          if (!token) { this.setToken(""); return window.location.href = "/expired";}

          const response = await axios.get(`${this.apiRoute}/admin/list-sendings`, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && response?.data) {
            return resolve(response.data);
          } else {
            return resolve(undefined);
          }
        } catch (err) {
          console.log(err);
          return reject(err);
        }
      }
    );
  }

  /**
   * editUser
   * @param id id da alterção
   * @param username nome da alteração
   * @param password senha da alteração
   * @param countConnections numero de conexões da alteração
   * @param isLocked lock da alteração
   * @returns booleano dizendo se foi executado com sucesso ou não
   */
  public static async editUser(id: number, username?: string, password?: string, countConnections?: number, isLocked?: boolean): Promise<boolean | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/admin/edit-user`, { id, username, password, countConnections, isLocked }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(undefined);
        }
      } catch (err) {
        return reject(err);
      }
    });
  }

  public static async getMessageInfo(whapperKey: string, messageId: number): Promise<ApiInterfaces.MessageInfo | undefined> {
    return new Promise(async (resolve, reject) => 
      {
        try {
          const token = await this.getToken();

          if (!token) { this.setToken(""); return window.location.href = "/expired"; }

          const response = await axios.post(`${this.apiRoute}/whapper/getMessageInfo`, { whapperKey, messageId }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

          if (response.auth && response.info) {
            return resolve(response.info);
          } else {
            return resolve(undefined);
          }
        } catch(err) {
          return reject(err);
        }
      }
    );
  }

  public static async removeWhapper(whapperKey: string): Promise<undefined | boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.getToken();

        if (!token) { this.setToken(""); return window.location.href = "/expired"; }

        const response = await axios.post(`${this.apiRoute}/whapper/remove-whapper`, { key: whapperKey }, { headers: { 'authorization': token, 'Content-Type': "application/json" } }).then(response => response.data);

        if (response.auth) {
          return resolve(true);
        } else {
          return resolve(undefined);
        }
      } catch (err) {
        return reject(err);
      }
    });
  }
}