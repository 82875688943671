import 'boxicons/css/boxicons.min.css';
import { useEffect, useState } from 'react';
import { ApiRequests } from '../api/ApiRequests';
import ConnectionsInto from "./components/connections";
import DashBoardInto from './components/dashboard';
import UserInto from './components/users';
import image from "./images/image.jpeg";
import { SiteElements } from './include/elements';
import "./styles/home.css";
import referLinks from '../utils/refer.links';
import Services from './components/services';
import Analytics from './components/analytics';

function Home() {
  const [nowPage, setNowPage] = useState<string>("Connections"); // Seta a página inciaa
  const [sideBar, setSideBar] = useState<boolean>(window.innerWidth > 768 ? false : true); // Informações sobre a side bar se ela vai ficar aberta ou nao
  const [myPublicInfo, setMyPublicInfo] = useState<undefined | { pLevel: number; cConnections: number; username: string; createdAt: string; email: string; relationshiKey: string; isLocked: boolean; phone: string; }>(undefined);
  const [profile, setProfile] = useState<boolean>(false); // Se é realmente para exibir o profile, o iconezinho ou nao.

  const fetchData = async () => {
    const info = await ApiRequests.GetMyPublicInfo(); // Fazendo a busca por informações do usuário que acabou de logar.

    if (info) {
      if (info.pLevel == 4) setNowPage("Dashboard"); // Se o Nivel de permissão for alto dar acesso a tudo para o usuário
      setMyPublicInfo(info); // Setando as informações do usuário que acabou de entrar na plataforma.
    }
  }

  useEffect(() => {
    if (ApiRequests.getAvailableToken().length == 0) {
      const param = new URLSearchParams(window.location.search);
      const token = param.get("token");
      if (token) {
        ApiRequests.setToken(token);

        (async () => {
          const isTokenOk = await ApiRequests.getToken();

          if (isTokenOk) {
          } else window.location.href = "/expired"
        }
        )();
      } else window.location.href = "/404"
    }

    (async () => await fetchData())();

    const sideBar = document.querySelector('.sidebar');

    const searchBtn = document.querySelector('.content nav form .form-input button');
    const searchBtnIcon = document.querySelector('.content nav form .form-input button .bx');
    const searchForm = document.querySelector('.content nav form');

    searchBtn?.addEventListener('click', function (e) {
      if (window.innerWidth < 576) {
        e.preventDefault();
        searchForm?.classList.toggle('show');
        if (searchForm?.classList.contains('show')) {
          searchBtnIcon?.classList.replace('bx-search', 'bx-x');
        } else {
          searchBtnIcon?.classList.replace('bx-x', 'bx-search');
        }
      }
    });

    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        sideBar?.classList.add('close');
      } else {
        sideBar?.classList.remove('close');
      }
      if (window.innerWidth > 576) {
        searchBtnIcon?.classList.replace('bx-x', 'bx-search');
        searchForm?.classList.remove('show');
      }
    });

    const toggler = document.getElementById('theme-toggle') as HTMLInputElement;

    toggler?.addEventListener('change', function () {
      if (toggler.checked) {
        SiteElements.isDark = true;
        document.body.classList.add('dark');
      } else {
        SiteElements.isDark = false;
        document.body.classList.remove('dark');
      }
    });
  }, []);

  const logoutAcc = async () => {
    try {
      await ApiRequests.LogoutAccount();
      window.location.href = referLinks.whapper_home;
    } catch(err) {
      console.log(err);
    }
  }

  return (
    <div>
      <div className={`sidebar${(sideBar ? ' close' : '')}`}>
        <a href="#" className="logo">
          <i className='bx bxs-dog'></i>
          <div className="logo-name"><span>W</span>ha<span>pp</span>er</div>
        </a>
        <ul className="side-menu">
          {
            (myPublicInfo && myPublicInfo.pLevel == 4) &&
            <>
              <li className={nowPage === "Dashboard" ? 'active' : ''}><a id="sidebarMenu" onClick={() => setNowPage("Dashboard")}><i className='bx bxs-dashboard'></i>Dashboard</a></li>
              <li className={nowPage === "Users" ? 'active' : ''}><a id="sidebarMenu" onClick={() => setNowPage("Users")}><i className='bx bx-group'></i>Users</a></li>
            </>
          }

          <li className={nowPage === "Connections" ? 'active' : ''}><a id="sidebarMenu" onClick={() => setNowPage("Connections")}><i className='bx bx-server' ></i>Connections</a></li>
          <li className={nowPage === "Services" ? 'active' : ''}><a id="sidebarMenu" onClick={() => setNowPage("Services")}><i className='bx bx-store-alt'></i>Services</a></li>
          <li className={nowPage === "Analytics" ? 'active' : ''}><a id="sidebarMenu" onClick={() => setNowPage("Analytics")}><i className='bx bx-analyse'></i>Analytics</a></li>
          <li className={nowPage === "Tickets" ? 'active' : ''}><a id="sidebarMenu" onClick={() => setNowPage("Tickets")}><i className='bx bx-message-square-dots'></i>Tickets</a></li>
          <li className={nowPage === "Settings" ? 'active' : ''}><a id="sidebarMenu" onClick={() => setNowPage("Settings")}><i className='bx bx-cog'></i>Settings</a></li>
        </ul>
        <ul className="side-menu">
          <li>
            <a onClick={logoutAcc} style={{cursor: "pointer"}} className="logout">
              <i className='bx bx-log-out-circle'></i>
              Logout
            </a>
          </li>
        </ul>
      </div>

      <div className="content">
        <nav>
          <i className='bx bx-menu' onClick={() => setSideBar(!sideBar)}></i>
          <form action="#">
            <div className="form-input">
              <input type="search" placeholder="Search..."></input>
              <button className="search-btn" type="submit"><i className='bx bx-search'></i></button>
            </div>
          </form>
          <input type="checkbox" id="theme-toggle" hidden></input>
          <label htmlFor="theme-toggle" className="theme-toggle"></label>
          <a href="#" className="notif">
            <i className='bx bx-bell'></i>
            <span className="count">12</span>
          </a>
          <a onClick={() => setProfile(!profile)} href="#" className="profile">
            <img src={image}></img>
          </a>
          {
            profile
            &&
            <div className='user-info dark'>
              <h3 className='greeting-info'>Olá, {myPublicInfo ? myPublicInfo.username.charAt(0).toUpperCase() + myPublicInfo.username.slice(1) : "User"}!</h3>
              <img className='image-info' src={image} alt="User Profile" />
              <h3 className='caracter-info'>{myPublicInfo ? myPublicInfo.pLevel === 4 ? "Administrador" : myPublicInfo.pLevel === 3 ? "Moderador" : "Cliente" : "Usuário"}</h3>
              <p className='email-info'>{myPublicInfo?.email || "email@example.com"}</p>
              <p className='phone-info'>{myPublicInfo?.phone || "(00) 00000-0000"}</p>
              <div className='btn-group'>
                <button onClick={() => setNowPage("Settings")} className='btn-info'><i className='bx bx-cog'></i> Configurações</button>
                <button onClick={logoutAcc} className='btn-logout'><i className='bx bx-log-out'></i> Logout</button>
              </div>
            </div>
          }
        </nav>

        <main>
          <div className="header">
            <div className="left">
              <h1>{nowPage}</h1>
              <ul className="breadcrumb">
                <li><a href="#">
                  Whapper
                </a></li>
                /
                <li><a href="#" className="active">{nowPage}</a></li>
              </ul>
            </div>
            {
              myPublicInfo?.pLevel == 4 && nowPage == "Dashboard"
              &&
              (
                <a href="#" className="report">
                  <i className='bx bx-cloud-download'></i>
                  <span>Download CSV</span>
                </a>
              )
            }
          </div>

          {myPublicInfo && myPublicInfo?.pLevel == 4 && nowPage == "Dashboard" && <DashBoardInto />}
          {myPublicInfo && myPublicInfo?.pLevel == 4 && nowPage == "Users" && <UserInto />}
          {myPublicInfo && myPublicInfo.pLevel >= 2 && nowPage == "Connections" && <ConnectionsInto />}
          {myPublicInfo && myPublicInfo.pLevel >= 2 && nowPage == "Services" && <Services />}
          {myPublicInfo && myPublicInfo.pLevel >= 2 && nowPage == "Analytics" && <Analytics />}
        </main>

      </div>
    </div>
  );
}

export default Home;
