import 'boxicons/css/boxicons.min.css';
import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { ApiInterfaces } from '../../api/ApiInterfaces';
import { ApiRequests } from '../../api/ApiRequests';
import '../styles/services.css';
import { SiteElements } from '../include/elements';
import EditCommandModal from './smallComponents/EditCommandModal';

function createTimeArray(): { label: string; value: string }[] {
  const result = [];
  const maxTimeInMs = 60 * 60 * 1000;
  const incrementInMs = 5 * 1000;

  for (let time = incrementInMs; time <= maxTimeInMs; time += incrementInMs) {
    const seconds = time / 1000;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    let label = '';
    if (minutes > 0) {
      label += `${minutes} minuto${minutes > 1 ? 's' : ''}`;
    }
    if (remainingSeconds > 0) {
      label += `${minutes > 0 ? ' e ' : ''}${remainingSeconds} segundo${remainingSeconds > 1 ? 's' : ''}`;
    }

    result.push({
      label: label || `${remainingSeconds} segundo${remainingSeconds > 1 ? 's' : ''}`,
      value: time.toString(),
    });
  }

  return result;
}

const services = [
  { name: 'Commands', icon: 'bx bx-code-curly' },
  { name: 'Forms', icon: 'bx bx-edit' },
  { name: 'AI', icon: 'bx bx-bot' },
  { name: 'Warming', icon: 'bx bxs-hot' },
  { name: 'Trigger', icon: 'bx bxs-envelope' },
  /*   { name: 'Calendar', icon: 'bx bxs-calendar' }, */
  { name: 'Protocolo', icon: 'bx bxs-package' }
];

const Services: React.FC = () => {
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [myServices, setMyServices] = useState<ApiInterfaces.ActiveServices>();
  const [selectedWhapper, setSelectedWhapper] = useState<ApiInterfaces.ListMyWhappers>();
  const [myWhappers, setMyWhappers] = useState<ApiInterfaces.ListMyWhappers[]>([]);
  const [commands, setCommands] = useState<ApiInterfaces.WhapperCommands[]>([]);
  const [charges, setCharges] = useState<ApiInterfaces.WhapperCharge>({});
  const [forms, setForms] = useState<ApiInterfaces.WhapperForms[]>([]);
  const [commandHasEqual, setCommandHasEqual] = useState(true);
  const [currentCommand, setCurrentCommand] = useState('');
  const [response, setResponse] = useState('');
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState<{command: string, response: string, id: number} | undefined>(undefined);
  const [selectedFormOption, setSelectedFormOption] = useState<{ value: string, label: string } | undefined>(undefined);
  const [selectedChargeOption, setSelectedChargeOption] = useState<{ value: string, label: string } | undefined>(undefined);
  const [messageForm, setMessageForm] = useState<string>('');

  const [chargeName, setChargeName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [chargePlaniliaId, setChargePlaniliaId] = useState<string>('');
  const [chargePlaniliaTabName, setChargePlaniliaTabName] = useState<string>('');
  const [selectedFormChargeOption, setSelectedFormChargeOption] = useState<{ value: string, label: string } | undefined>(undefined);
  const [selectedWhapperChargeOption, setSelectedWhapperChargeOption] = useState<{ value: string, label: string } | undefined>(undefined);
  const [selectedTimeChargeOption, setSelectedTimeChargeOption] = useState<{ value: string, label: string } | undefined>(undefined);


  useEffect(() => {
    // Funções de busca na API
    const UsersData = async () => {
      const meServices = await ApiRequests.GetMyActiveServices(); // Buscando os usuários na plataforma.
      const allMyWhappers = await ApiRequests.ListMyWhappers(); // Buscando os Usuários do cliente na plataforma.
      const allMyCommands = await ApiRequests.ListMyCommands(); // Busca todos os comandos de todos os whappers disponiveis para esse usuário
      const allMyForms = await ApiRequests.ListMyForms(); // Busca todas as formas do usuário conectado na plataforma

      
      setCommands(allMyCommands);
      setForms(allMyForms);
      
      if (meServices && allMyWhappers) {
        if (meServices.trigger.active) {
          const allMyCharges = await ApiRequests.ListMyCharges();
          setCharges(allMyCharges);
        }
        setMyServices(meServices);
        setMyWhappers(allMyWhappers);
      }
    }

    UsersData()

    // Consulta à API a cada 5 segundos (ajuste o intervalo conforme necessário)
    const intervalId = setInterval(UsersData, 5000);

    // Certifique-se de limpar o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []);

  const chargeOptions = Object.values(charges)
    .map(charge => ({label: `${charge[0].sheet.triggerCodeName} - ${charge[0].sheet.triggerCode}`, value: charge[0].sheet.triggerCode}));

  const handleSelectWhapper = (whapperLabelValue: SingleValue<{ label: string, value: string }>) => setSelectedWhapper(myWhappers.find(whapper => whapperLabelValue && whapper.key == whapperLabelValue.value))

  const isServiceActive = (serviceName: string): boolean => {
    if (!myServices) return false;
    const serviceKey = serviceName.toLowerCase() as keyof ApiInterfaces.ActiveServices;
    return myServices[serviceKey]?.active;
  };

  const handleServiceClick = (service: string) => {
    if (service == selectedService) return setSelectedService(null);

    setResponse("");
    setCurrentCommand("");
    setSelectedCommand(undefined);
    setSelectedWhapper(undefined);
    setSelectedService(service);
  };

  const options = myWhappers
    .filter(whapper => typeof whapper.active !== 'string')
    .map(whapper => ({ value: whapper.key, label: whapper.name }));

  const formsOptions = forms
    .map(form => ({value: form.code, label: `${form.code} - ${form.message}`}));

  const handleAddCommand = async () => {
    try {
      if (currentCommand && response && selectedWhapper) {
        if (commands.find(cmd => cmd.firstMessage.toLowerCase() == currentCommand.toLowerCase() && cmd.whapperKey == selectedWhapper.key)) return toast.warning("Esse comando já existe nesta instância!");
  
        const requestAddCommand = await ApiRequests.CreateNewCommand(selectedWhapper.key, currentCommand, response);
  
        if (requestAddCommand) {
          setCurrentCommand('');
          setResponse('');
  
          toast.success("Sucesso! Novo comando adicionado para " + selectedWhapper.name);
  
          const allMyCommands = await ApiRequests.ListMyCommands(); // Busca todos os comandos de todos os whappers disponiveis para esse usuário
  
          setCommands(allMyCommands);
        } else return toast.warning("Não foi possível inserir o comando para essa instância.");
  
      } else return toast.warning("Certifique-se que haja uma instância selecionada.");
    } catch(err) {
      toast.error("(500) Erro interno no servidor!");
    }
  };

  const handleRemoveCommand = async () => {
    try {
      if (!selectedCommand) return toast.warning("É preciso ter um comando selecionado para realizar essa ação.");

      const requestRemoveCommand = await ApiRequests.RemoveCommand(selectedCommand.id);

      if (requestRemoveCommand) {
        setSelectedCommand(undefined);
        toast.success("Sucesso! O comando foi removido!");

        const allMyCommands = await ApiRequests.ListMyCommands(); // Busca todos os comandos de todos os whappers disponiveis para esse usuário

        setCommands(allMyCommands);
      }
    } catch (err) {
      toast.error("(500) Erro interno no servidor!");
    }
  }

  const handleSelectCommand = (value: SingleValue<{ label: string; value: number; }>) => {
    const currentCommand = commands.find(cmd => cmd.id == value?.value);
    
    if (currentCommand) {
      setSelectedCommand({command: currentCommand.firstMessage, response: currentCommand.message, id: currentCommand.id });

    } else toast.warn("Não foi possivel localizar o comando.");
  }

  const openModal = () => setIsModalEditOpen(true);
  const closeModal = () => setIsModalEditOpen(false);

  // Forms
  const handleSelectForm = (value: { value: string; label: string; } | null) => { 
    if (value) { 
      setSelectedFormOption(value); 
      const formMessage = forms.find(form => form.code == value.value);
      if (formMessage) setMessageForm(formMessage.message);
    } else { 
      setSelectedFormOption(undefined); 
      setMessageForm(""); 
    }
  }

  const handleDeleteForm = async (): Promise<any> => {
    try {
      if (!selectedFormOption) return toast.warning("É preciso que uma forma seja selecionada!");

      const deleteForm = await ApiRequests.RemoveForm(selectedFormOption.value);

      if (deleteForm) {
        const allMyForms = await ApiRequests.ListMyForms();
        setForms(allMyForms);
        setSelectedFormOption(undefined);
        setMessageForm("");
        return toast.success(`Sucesso, forma deletada com sucesso!`);
      } else {
        return toast.warning("Não foi possível deletar a forma no momento. Tente novamente mais tarde.");
      }
    } catch(err) {
      console.log(err);
      return toast.error("(500) Erro interno no servidor. Tente novamente mais tarde.");
    }
  }

  const handleActionForm = async (): Promise<any> => {
    try {
      if (!messageForm.length) return toast.warning("Não é possível criar formas vazias!");

      if (selectedFormOption) {
        const updateForm = await ApiRequests.UpdateForm(selectedFormOption.value, messageForm);

        if (updateForm) {
          const allMyForms = await ApiRequests.ListMyForms();
          setForms(allMyForms);
          setSelectedFormOption(undefined);
          setMessageForm("");
          return toast.success("Sucesso, forma atualizada com sucesso!");
        } else {
          return toast.warning("Não foi possível atualizar a forma no momento. Tente novamente mais tarde.");
        }
      } else {
        const newForm = await ApiRequests.CreateNewForm(messageForm);

        if (newForm) {
          const allMyForms = await ApiRequests.ListMyForms();
          setForms(allMyForms);
          setSelectedFormOption(undefined);
          setMessageForm("");
          return toast.success(`Sucesso, forma criada com sucesso! Id: ${newForm}`);
        } else {
          return toast.warning("Não foi possível criar a forma no momento. Tente novamente mais tarde.");
        }
      }
    } catch(err) {
      console.log(err);
      return toast.error("(500) Erro interno no servidor. Tente novamente mais tarde.");
    }
  }

  // Charges
  const handleSelectCharge = (value: { value: string; label: string; } | null) => {
    if (value) {
      setSelectedChargeOption(value);
    } else {
      setSelectedChargeOption(undefined);
    }
  }

  const handleSelectChargeForm = (value: { value: string; label: string; } | null) => {
    if (value) {
      setSelectedFormChargeOption(value);
    } else {
      setSelectedFormChargeOption(undefined);
    }
  }

  const handleSelectChargeWhapper = (value: { value: string; label: string; } | null) => {
    if (value) {
      setSelectedWhapperChargeOption(value);
    } else {
      setSelectedWhapperChargeOption(undefined);
    }
  }

  const handleSelectChargeTime = (value: { value: string; label: string; } | null) => {
    if (value) {
      setSelectedTimeChargeOption(value);
    } else {
      setSelectedTimeChargeOption(undefined);
    }
  }

  const handleCreateCharge = async (): Promise<any> => {
    try {
      if (!chargeName.length || !chargePlaniliaId.length || !chargePlaniliaTabName.length || !selectedFormChargeOption || !selectedTimeChargeOption) return toast.warning("Não é possível criar cargas com informações faltando!");

      const hasName = Object.values(charges).find(charge => charge[0].sheet.triggerCodeName.toLowerCase() == chargeName.toLowerCase());

      if (hasName) return toast.warning("Já existe uma carga com esse nome!");

      const createCharge = await ApiRequests.CreateNewCharge(chargePlaniliaId, selectedFormChargeOption.value, chargePlaniliaTabName, parseInt(selectedTimeChargeOption.value), chargeName);

      if (createCharge) {
        setChargeName("");
        setChargePlaniliaId("");
        setChargePlaniliaTabName("");
        setSelectedFormChargeOption(undefined);
        setSelectedTimeChargeOption(undefined);
        const allMyCharges = await ApiRequests.ListMyCharges();
        setCharges(allMyCharges);
        
        return toast.success("Sucesso ao criar a carga!");
      } else {
        return toast.warning("Falha ao criar a carga. Tente novamente mais tarde!");
      }
    } catch (err) {
      console.log(err);
      return toast.error("(500) Erro interno no servidor. Tente novamente mais tarde.");
    }
  }

  const handleStartCharge = async (): Promise<any> => {
    try {
      if (!selectedChargeOption) return toast.warning("Selecione uma carga para disparar, antes!");
      if (!selectedWhapperChargeOption) return toast.warning("Selecione um Whapper para enviar a carga!");

      const startCharge = await ApiRequests.StartCharge(selectedChargeOption.value, selectedWhapperChargeOption.value);

      if (startCharge) {
        setSelectedChargeOption(undefined);
        setSelectedWhapperChargeOption(undefined);

        return toast.success("O disparo iniciou! Acompanhe-o na planília!");
      } else {
        return toast.warning("Falha ao iniciar a carga. Tente novamente mais tarde!");
      }
    } catch (err) {
      console.log(err);
      return toast.error("(500) Erro interno no servidor. Tente novamente mais tarde.");
    }
  }

  const handleDeleteCharge = async (): Promise<any> => {
    try {
      if (!selectedChargeOption) return toast.warning("Selecione uma carga para remover, antes!");

      const deleteCharge = await ApiRequests.DeleteCharge(selectedChargeOption.value);

      if (deleteCharge) {
        setSelectedChargeOption(undefined);
        const allMyCharges = await ApiRequests.ListMyCharges();
        setCharges(allMyCharges);

        return toast.success("Sucesso! A Carga foi removida!");
      } else {
        return toast.warning("Falha ao deletar a carga. Tente novamente mais tarde!");
      }
    } catch (err) {
      console.log(err);
      return toast.error("(500) Erro interno no servidor. Tente novamente mais tarde.");
    }
  }

  const handleSendEmail = async (): Promise<any> => {
    try {
      if (!email.length) return toast.warning("!");

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(email)) return toast.warning("Email não válido para compartilhamento!");

      const sendMail = await ApiRequests.SendEmail(email);

      if (sendMail) {
        return toast.success("Sucesso! Planília de envios compartilhada com o destino!");
      } else {
        return toast.warning("Falha ao compartilhar a planília. Tente novamente mais tarde!");
      }
    } catch (err) {
      console.log(err);
      return toast.warning("Email já foi adicionado a lista de emails compartilhados!");
    }
  }

  return (
    <div className="service-editor">
      <div className="service-list">
        {services.map(service => (
          <div
            key={service.name}
            className={`service-item ${selectedService === service.name ? 'selected' : ''}`}
            onClick={() => handleServiceClick(service.name)}
          >
            <i className={`service-icon ${service.icon}`}></i>
            <span className="service-name">{service.name}</span>
            <i className={`${isServiceActive(service.name) || isServiceActive(service.name) == undefined ? 'bx bxs-lock-open-alt' : 'bx bxs-lock-alt'} lock-icon`}></i>
          </div>
        ))}
      </div>
      {
        !selectedService
        &&
        <div className="editor">
          <p className='select-service-text'>Selecione um Serviço...</p>
        </div>
      }

      {
        selectedService
        &&
        selectedService == "Commands"
        &&
        (
          <div className='commands-box'>
            <div className='input-section'>
              <h4 className='header-select-instance'> <i className='bx bx-mobile-alt'></i> Selecione uma instância</h4>
              <Select
                styles={SiteElements.isDark ? {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #25252C',
                    boxShadow: 'none',
                    background: '#25252C',
                    color: 'white',
                    borderColor: 'gray',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'grey',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: '#25252C',
                    color: 'white',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#181A1E' : '#25252C',
                    color: state.isFocused ? 'white' : provided.color,
                  }),
                } : {
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    boxShadow: state.isFocused ? '0 0 0 1px #19D257' : base.boxShadow,
                    '&:hover': {
                      borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#97fcb9' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                    '&:hover': {
                      backgroundColor: '#97fcb9',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'black',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: 'white',
                    color: 'black',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                }}
                onChange={(value) => handleSelectWhapper(value)}
                className='select-instance'
                placeholder="Selecione uma instância!"
                options={options}
              />
              <h4 className='header-select-instance'> <i className='bx bx-command' ></i> Crie um novo comando.</h4>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  className='addCommand'
                  type='text'
                  disabled={selectedWhapper ? false : true}
                  placeholder='Digite o comando'
                  value={currentCommand}
                  onChange={e => setCurrentCommand(e.target.value)}
                />
                <i
                  title={commandHasEqual ? `O comando deve ser exatamente igual a ${currentCommand || "qualquer coisa"}.` : `O comando pode ter qualquer formatação desde que seja igual a ${currentCommand || "qualquer coisa"}.`}
                  onClick={() => selectedWhapper ? setCommandHasEqual(!commandHasEqual) : undefined}
                  className={`${commandHasEqual ? "bx bx-layer-minus" : "bx bx-layer-plus"} iconHover`}
                ></i>
              </div>
              <textarea
                className='returnCommand'
                placeholder='Resposta do comando'
                disabled={selectedWhapper ? false : true}
                value={response}
                onChange={e => setResponse(e.target.value)}
              ></textarea>
              <button onClick={handleAddCommand}>Adicionar Comando</button>
            </div>
            <div className='commands-list'>
              <h2 className='header-select-instance'><i className='bx bx-food-menu'></i> Comandos Criados</h2>
              <Select
                isDisabled={selectedWhapper ? false : true}
                styles={SiteElements.isDark ? {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #25252C',
                    boxShadow: 'none',
                    background: '#25252C',
                    color: 'white',
                    borderColor: 'gray',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'grey',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: '#25252C',
                    color: 'white',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#181A1E' : '#25252C',
                    color: state.isFocused ? 'white' : provided.color,
                  }),
                } : {
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    boxShadow: state.isFocused ? '0 0 0 1px #19D257' : base.boxShadow,
                    '&:hover': {
                      borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#97fcb9' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                    '&:hover': {
                      backgroundColor: '#97fcb9',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'black',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: 'white',
                    color: 'black',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                }}
                onChange={(value) => {handleSelectCommand(value) }}
                className='select-command'
                placeholder="Selecione um comando!"
                options={commands.filter(command => command.whapperKey == selectedWhapper?.key).map(cmd => ({ label: `${cmd.firstMessage} - ${cmd.message}`, value: cmd.id }))}
              />
              <div className="button-group">
                <button className="btn edit" disabled={selectedCommand ? false : true} onClick={openModal}><i className="bx bx-edit"></i> Editar</button>
                <button className="btn delete" disabled={selectedCommand ? false : true} onClick={handleRemoveCommand}><i className="bx bx-trash"></i> Excluir</button>
              </div>
              <div className='chat-box'>
                <div className='chat-bubble left'>{currentCommand.length > 0 ? currentCommand : "Opa! Boa tarde! Pode me ajudar?"}</div>
                {
                  response.length > 0
                    ? 
                    <div className='chat-bubble right'>{forms.find(form => form.code.toLowerCase() == response.toLowerCase()) ? (forms.find(form => form.code.toLowerCase() == response.toLowerCase())?.message as string).replace(/{[^}]+}/g, 'VARIABLE') : response.split('\n').map((item, key) => <span key={key}>{item}<br /></span>)}</div>
                    : 
                  <div className='chat-bubble right'>🫠 Ops! Ainda não consigo responder essa mensagem!</div>
                }
              </div>
            </div>
          </div>
        )
      }
      {
        selectedService
        &&
        selectedService == "Forms"
        &&
        (
          <div className='commands-box'>
            <div className='input-section'>
              <h4 className='header-select-instance'> <i className='bx bx-mobile-alt'></i> Crie ou Edite uma Forma</h4>
              <Select
                styles={SiteElements.isDark ? {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #25252C',
                    boxShadow: 'none',
                    background: '#25252C',
                    color: 'white',
                    borderColor: 'gray',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'grey',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: '#25252C',
                    color: 'white',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#181A1E' : '#25252C',
                    color: state.isFocused ? 'white' : provided.color,
                  }),
                } : {
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    boxShadow: state.isFocused ? '0 0 0 1px #19D257' : base.boxShadow,
                    '&:hover': {
                      borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#97fcb9' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                    '&:hover': {
                      backgroundColor: '#97fcb9',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'black',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: 'white',
                    color: 'black',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                }}
                onChange={(value) => handleSelectForm(value)}
                className='select-instance'
                placeholder="Selecione uma forma existente para editar."
                isClearable={true}
                options={formsOptions}
              />
              <textarea
                className='returnCommand'
                placeholder='Resposta do comando'
                value={messageForm}
                onChange={e => setMessageForm(e.target.value)}
              ></textarea>
              <button onClick={() => handleActionForm()}>{selectedFormOption ? "Atualizar Forma" : "Criar Forma"}</button>
              {
                selectedFormOption 
                  &&
                <button className='button-delete' onClick={() => handleDeleteForm()}>Deletar Forma</button>
              }
              
            </div>
            <div className='commands-list'>
              <h2 className='header-select-instance'><i className='bx bx-detail'></i> Visualização de Forma</h2>
              <div className='chat-box'>
                <div className='chat-bubble right'>{messageForm.length ? messageForm.replace(/{[^}]+}/g, 'VARIABLE') : "😎 Digite as informações, e veja como vai ficar por aqui!"}</div>
              </div>
            </div>
          </div>
        )
      }
      {
        selectedService
        &&
        selectedService == "AI"
        &&
        (
          <div className="editor">
            <p className='select-service-text'>Whapper AI está em Manutenção!</p>
          </div>
        )
      }
      {
        selectedService
        &&
        selectedService == "Warming"
        &&
        (
          <div className="editor">
            <p className='select-service-text'>Whapper Warming está em Manutenção!</p>
          </div>
        )
      }
      {
        selectedService
        &&
        selectedService == "Trigger"
        &&
        myServices!.trigger.active
        ?
        (
          <div className='commands-box'>
            <div className='input-section'>
              <h4 className='header-select-instance'> <i className='bx bxs-plane-alt'></i> Crie um Disparo com o Trigger</h4>
              <h3><i style={{ fontSize: "17px" }} className='bx bxs-flame'></i> Qual será o nome dessa carga?</h3>
              <input
                type="text"
                className="custom-input"
                placeholder="Nome da Carga."
                value={chargeName}
                onChange={(event) => setChargeName(event.target.value)}
              />
              <h3><i style={{fontSize: "15px"}} className='bx bxs-message-dots'></i> Qual será a forma para disparo?</h3>
              <Select
                styles={SiteElements.isDark ? {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #25252C',
                    boxShadow: 'none',
                    background: '#25252C',
                    color: 'white',
                    borderColor: 'gray',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'grey',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: '#25252C',
                    color: 'white',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#181A1E' : '#25252C',
                    color: state.isFocused ? 'white' : provided.color,
                  }),
                } : {
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    boxShadow: state.isFocused ? '0 0 0 1px #19D257' : base.boxShadow,
                    '&:hover': {
                      borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#97fcb9' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                    '&:hover': {
                      backgroundColor: '#97fcb9',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'black',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: 'white',
                    color: 'black',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                }}
                onChange={(value) => {handleSelectChargeForm(value)}}
                className='select-instance'
                placeholder="Selecionar forma"
                isClearable={true}
                options={formsOptions}
              />
              <h3><i style={{ fontSize: "15px" }} className='bx bxs-hourglass'></i> Qual será a distancia de tempo entre cada envio?</h3>
              <Select
                styles={SiteElements.isDark ? {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #25252C',
                    boxShadow: 'none',
                    background: '#25252C',
                    color: 'white',
                    borderColor: 'gray',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'grey',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: '#25252C',
                    color: 'white',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#181A1E' : '#25252C',
                    color: state.isFocused ? 'white' : provided.color,
                  }),
                } : {
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    boxShadow: state.isFocused ? '0 0 0 1px #19D257' : base.boxShadow,
                    '&:hover': {
                      borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#97fcb9' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                    '&:hover': {
                      backgroundColor: '#97fcb9',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'black',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: 'white',
                    color: 'black',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                }}
                onChange={(value) => { handleSelectChargeTime(value) }}
                className='select-instance'
                placeholder="Selecione um Tempo"
                isClearable={true}
                options={createTimeArray()}
              />
              <h3><i style={{ fontSize: "17px" }} className='bx bxs-spreadsheet'></i> Qual o Id da Planília para disparo?</h3>
              <input
                type="text"
                className="custom-input"
                placeholder="Id da Planília."
                value={chargePlaniliaId}
                onChange={(event) => setChargePlaniliaId(event.target.value)}
              />
              <h3><i style={{ fontSize: "15px" }} className='bx bxs-rename'></i> Qual o nome da aba com os dados de envio?</h3>
              <input
                type="text"
                className="custom-input"
                placeholder="Nome da Aba."
                  value={chargePlaniliaTabName}
                  onChange={(event) => setChargePlaniliaTabName(event.target.value)}
              />
                <button onClick={() => handleCreateCharge()}>Criar Carga</button>

            </div>
            <div className='commands-list'>
              <h2 className='header-select-instance'><i className='bx bx-detail'></i> Realizar Ações Sobre uma Carga</h2>
              <Select
                styles={SiteElements.isDark ? {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #25252C',
                    boxShadow: 'none',
                    background: '#25252C',
                    color: 'white',
                    borderColor: 'gray',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'grey',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: '#25252C',
                    color: 'white',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#181A1E' : '#25252C',
                    color: state.isFocused ? 'white' : provided.color,
                  }),
                } : {
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    boxShadow: state.isFocused ? '0 0 0 1px #19D257' : base.boxShadow,
                    '&:hover': {
                      borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#97fcb9' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                    '&:hover': {
                      backgroundColor: '#97fcb9',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'black',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: 'white',
                    color: 'black',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                }}
                onChange={(value) => { handleSelectCharge(value) }}
                className='select-instance'
                placeholder="Selecione a Carga"
                isClearable={true}
                options={chargeOptions}
              />
              <h3><i style={{ fontSize: "15px" }} className='bx bxs-ghost'></i> Qual Whapper será responsável pelo envio?</h3>
              <Select
                styles={SiteElements.isDark ? {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #25252C',
                    boxShadow: 'none',
                    background: '#25252C',
                    color: 'white',
                    borderColor: 'gray',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'grey',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: '#25252C',
                    color: 'white',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#181A1E' : '#25252C',
                    color: state.isFocused ? 'white' : provided.color,
                  }),
                } : {
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    boxShadow: state.isFocused ? '0 0 0 1px #19D257' : base.boxShadow,
                    '&:hover': {
                      borderColor: state.isFocused ? '#19D257' : base.borderColor,
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#97fcb9' : base.backgroundColor,
                    color: state.isFocused ? 'black' : base.color,
                    '&:hover': {
                      backgroundColor: '#97fcb9',
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'black',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    background: 'white',
                    color: 'black',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'gray',
                    },
                  }),
                }}
                isDisabled={selectedChargeOption ? false : true}
                onChange={(value) => { handleSelectChargeWhapper(value) }}
                className='select-instance'
                placeholder="Selecionar Whapper"
                isClearable={true}
                options={options}
              />
 
              <h3><i style={{ fontSize: "17px" }} className='bx bxs-data'></i> Código da Carga: {selectedChargeOption ? Object.values(charges).find(charge => charge[0].sheet.triggerCode == selectedChargeOption.value)![0].sheet.triggerCode : "Não selecionado."}</h3>
              <h3><i style={{ fontSize: "17px" }} className='bx bxs-data'></i> Nome da Carga: {selectedChargeOption ? Object.values(charges).find(charge => charge[0].sheet.triggerCode == selectedChargeOption.value)![0].sheet.triggerCodeName : "Não selecionado."}</h3>
              <h3><i style={{ fontSize: "17px" }} className='bx bxs-data'></i> Forma da Carga: {selectedChargeOption ? Object.values(charges).find(charge => charge[0].sheet.triggerCode == selectedChargeOption.value)![0].charge.fromCode : "Não selecionado."}</h3>
              <h3><i style={{ fontSize: "17px" }} className='bx bxs-data'></i> Distancia entre Envios: {selectedChargeOption ? parseInt(Object.values(charges).find(charge => charge[0].sheet.triggerCode == selectedChargeOption.value)![0].charge.awaitTime)/1000 + " segundos." : "Não selecionado."}</h3>
              <h3><i style={{ fontSize: "17px", marginBottom: "15px" }} className='bx bxs-data'></i> Tempo estimado: {selectedChargeOption ? Object.values(charges).find(charge => charge[0].sheet.triggerCode == selectedChargeOption.value)![0].charge.estimatedTime : "Não selecionado."}</h3>
              
                <button style={{ cursor: selectedChargeOption ? "pointer" : "not-allowed" }} onClick={() => handleStartCharge() }>Disparar Carga</button>
                <button className='button-delete' style={{ cursor: selectedChargeOption ? "pointer" : "not-allowed", marginBottom: "15px" }} onClick={() => { handleDeleteCharge() }}>Deletear Carga</button>

              <h2 className='header-select-instance'><i className='bx bxl-gmail'></i> Compartilhe a Planília de Disparo com seu Email</h2>
              <input
                type="text"
                className="custom-input"
                placeholder="Digite seu email aqui."
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
                <button style={{ cursor: selectedChargeOption ? "pointer" : "not-allowed" }} onClick={() => handleSendEmail() }>Compartilhar</button>
            </div>
          </div>
        )
          :
        (
          <div className="editor">
              <p className='select-service-text'>Esse serviço está bloqueado para sua conta.</p>
          </div>
        )
      }
      {
        selectedService
        &&
        selectedService == "Protocolo"
        &&
        (
          <div className="editor">
            <p className='select-service-text'>Whapper Protocolo está em Manutenção!</p>
          </div>
        )
      }
      {isModalEditOpen && selectedWhapper && selectedCommand && <EditCommandModal info={(type, message) => toast[type](message)} commandId={selectedCommand.id} nowCommand={selectedCommand.command} nowResponse={selectedCommand.response} closeModal={closeModal} />}
      <ToastContainer
        stacked
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Services;